<template>
  <panel class="dashboard-details">
    <div class="dashboard-details__header">
      <p class="dashboard-details__meta">
        ID: {{ list.id }}
      </p>

      <heading
        tag="h3"
        level="2"
        class="dashboard-details__heading"
      >
        <template v-if="!editName">
          {{ formattedHeading }}
          <btn
            icon="pencil"
            class="dashboard-details__edit-btn"
            @click="editName = true"
          />
        </template>
        <form
          v-else
          @submit.prevent="changeName"
        >
          <input-field
            v-model="newName"
            :placeholder="formattedHeading"
          />
        </form>
      </heading>

      <p
        v-if="list.file_url"
        class="dashboard-details__meta"
      >
        Источник: <strong>{{ list.file_url }}</strong>
      </p>
    </div>

    <div class="dashboard-details__progress">
      <dashboard-progress
        :list="list"
        :is-loading="isActionsLoading"
        :type="type"
        @start="$emit('start')"
        @restart="$emit('restart')"
        @delete="$emit('delete')"
      />
    </div>

    <shop
      v-if="list.shop_name !== undefined"
      :shop="shop"
      show-marketplace
    />

    <ul class="dashboard-details__list">
      <li class="dashboard-details__item">
        <dl class="dashboard-details__property">
          <dt class="dashboard-details__property-title">
            Статус:
          </dt>
          <dd class="dashboard-details__property-content">
            {{ status }}
          </dd>
        </dl>
      </li>

      <li class="dashboard-details__item">
        <dl class="dashboard-details__property">
          <dt class="dashboard-details__property-title">
            Дата задания:
          </dt>
          <dd class="dashboard-details__property-content">
            {{ formatDate(list.created) }}
          </dd>
        </dl>
      </li>

      <li class="dashboard-details__item">
        <dl class="dashboard-details__property">
          <dt class="dashboard-details__property-title">
            Всего товаров:
          </dt>
          <dd
            v-if="type === 'import'"
            class="dashboard-details__property-content"
          >
            {{ list.products.total }}
          </dd>
          <dd
            v-else
            class="dashboard-details__property-content"
          >
            {{ list.item_count.total }}
          </dd>
        </dl>
      </li>

      <li class="dashboard-details__item">
        <dl
          v-if="type === 'import'"
          class="dashboard-details__property"
        >
          <dt class="dashboard-details__property-title">
            Загружено в базу:
          </dt>
          <dd class="dashboard-details__property-content">
            {{ list.products.imported }}
          </dd>
        </dl>
        <dl
          v-else
          class="dashboard-details__property"
        >
          <dt class="dashboard-details__property-title">
            Выгружено:
          </dt>
          <dd class="dashboard-details__property-content">
            {{ list.item_count.imported }}
          </dd>
        </dl>
      </li>

      <li class="dashboard-details__item">
        <dl class="dashboard-details__property">
          <dt class="dashboard-details__property-title">
            Осталось:
          </dt>
          <dd
            v-if="type === 'import'"
            class="dashboard-details__property-content"
          >
            {{ list.products.remaining }}
          </dd>
          <dd
            v-else
            class="dashboard-details__property-content"
          >
            {{ list.item_count.remaining }}
          </dd>
        </dl>
      </li>
    </ul>

    <notification
      class="dashboard-details__notification"
      :type="notificationType"
      :icon="notificationIcon"
    >
      {{ notificationText }}
    </notification>
  </panel>
</template>

<script>
import Panel from '@/components/Common/Panel.vue'
import Heading from '@/components/Common/Heading.vue'
import Notification from '@/components/Interface/Notification.vue'
import Shop from '@/components/Common/Shop.vue'
import formatDate from '@/utils/date-formatter'
import DashboardProgress from './DashboardProgress'
import Btn from '@/components/Interface/Button.vue'
import InputField from '@/components/Interface/Input.vue'
import {
  statuses,
  notificationTexts,
  notificationTypes,
  notificationIcons
} from '@/constants/product-import-statuses'

export default {
  components: {
    Panel,
    Heading,
    Notification,
    Shop,
    DashboardProgress,
    Btn,
    InputField
  },

  props: {
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => ({})
    },
    isActionsLoading: Boolean
  },

  data () {
    return {
      editName: false,
      newName: ''
    }
  },

  computed: {
    formattedHeading () {
      return this.type === 'import' ? `Список на импорт “${this.list.name}”` : `Список на экспорт “${this.list.name}”`
    },

    shop () {
      return {
        name: this.list.shop_name,
        marketplace: this.list.marketplace_code,
        marketplaceName: this.list.marketplace_name
      }
    },

    status () {
      return statuses[this.list.status]
    },

    notificationType () {
      return notificationTypes[this.list.status]
    },

    notificationIcon () {
      return notificationIcons[this.list.status]
    },

    notificationText () {
      return notificationTexts[this.type][this.list.status]
    }
  },

  methods: {
    formatDate,

    changeName () {
      this.$emit('change:name', this.newName)
      this.editName = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .dashboard-details
    display flex
    flex-wrap wrap
    height 100%
    padding 30px
    box-sizing border-box

    &__header,
    &__progress
      flex-basis 50%

    &__meta
      margin-bottom 4px
      font-size 14px
      color #7b7b85

      strong
        color #3c3d47

    &__heading
      margin-bottom 5px

    &__list
      display flex
      justify-content space-between
      width 100%
      margin 0
      margin-top 20px
      margin-bottom 30px
      padding 0
      list-style none

    &__item
      flex-basis 25%

    &__property
      font-size 14px

    &__property-title
      margin-bottom 4px
      color #7b7b85

    &__property-content
      font-family 'proxima_nova_bold'

    &__notification
      >>> .svg-icon--pause
        color #f3bb1c
</style>
