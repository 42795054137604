<template>
  <dashboard-details
    :type="type"
    :list="list"
    :is-actions-loading="isActionsLoading"
    @start="$emit('start')"
    @restart="$emit('restart')"
    @delete="$emit('delete')"
    @change:name="$emit('change:name', $event)"
  />
</template>

<script>
import DashboardDetails from './DashboardDetails.vue'

export default {
  components: {
    DashboardDetails
  },

  props: {
    type: {
      type: String,
      default: 'import'
    },
    list: {
      type: Object,
      required: true
    },
    isActionsLoading: Boolean
  }
}
</script>
