<template>
  <div class="dashboard-progress">
    <ul
      v-if="isShowActions"
      class="dashboard-progress__actions"
    >
      <li class="dashboard-progress__action">
        <btn
          v-if="isShowDeleteAction"
          secondary
          block
          icon="trash"
          @click="$emit('delete')"
        >
          Удалить список
        </btn>
      </li>

      <li
        v-if="isShowStartAction"
        class="dashboard-progress__action"
      >
        <btn
          primary
          block
          icon="play"
          :is-load="isLoading"
          @click="$emit('start')"
        >
          Запустить
        </btn>
      </li>

      <li
        v-if="isShowPauseAction"
        class="dashboard-progress__action"
      >
        <btn
          primary
          block
          :is-load="isLoading"
          icon="pause"
        >
          Пауза
        </btn>
      </li>

      <li
        v-if="isShowRestartAction"
        class="dashboard-progress__action"
      >
        <btn
          primary
          block
          icon="play"
          :is-load="isLoading"
          @click="$emit('restart')"
        >
          Перезапустить
        </btn>
      </li>
    </ul>
  </div>
</template>

<script>
import Btn from '@/components/Interface/Button.vue'
import { startableStatuses, restartableStatuses, deletableStatuses } from '@/constants/product-import-statuses'

export default {

  components: {
    Btn
  },
  props: {
    list: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    isLoading: Boolean
  },

  computed: {
    isShowActions () {
      return this.list.status !== 'finished'
    },

    isShowDeleteAction () {
      return deletableStatuses[this.type].includes(this.list.status)
    },

    isShowStartAction () {
      return startableStatuses[this.type].includes(this.list.status)
    },

    isShowPauseAction () {
      const { status } = this.list

      return status === 'working'
    },

    isShowRestartAction () {
      return restartableStatuses.includes(this.list.status)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .dashboard-progress
    height 100%
    padding 30px
    padding-bottom 0
    box-sizing border-box

    &__actions
      display flex
      justify-content space-between
      margin 0
      padding 0
      list-style none

    &__action
      flex-basis 48%
</style>
