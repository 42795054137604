<template>
  <div
    class="notification"
    :class="notificationClasses"
  >
    <div
      class="notification__in"
      :style="`max-height: ${maxHeight}px;`"
    >
      <svg-icon
        v-if="icon"
        :name="icon"
        class="notification__icon"
      />
      <slot />
    </div>
  </div>
</template>

<script>
const types = [
  'success',
  'error',
  'warning',
  'info'
]

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    notificationClasses () {
      const currentType = types.find(t => t === this.type)
      return {
        [`notification_viewtype_${currentType}`]: currentType,
        'notification_fixed-height': this.maxHeight
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .notification
    width 100%
    padding 8px
    font-size 14px
    background-color #cacad3
    border-radius 4px
    box-sizing border-box

    &__in
      display flex
      align-items center

    &__icon
      width 13px
      height 13px
      margin-right 10px

    &_viewtype_success
      background-color rgba(#3cc13b, 0.1)
      border 1px solid rgba(#3cc13b, 0.5)

    &_viewtype_error
      background-color #fef6f4
      border 1px solid #f79b9c

    &_viewtype_warning
      background-color #fefcf4
      border 1px solid #f9dd8e

    &_viewtype_info
      background-color rgba(#5346e4, 0.1)
      border 1px solid rgba(#5346e4, 0.5)

    &_fixed-height
      .notification__in
        display block
        overflow-y scroll

        &::-webkit-scrollbar
          width 6px
          -webkit-appearance none

        &::-webkit-scrollbar-thumb
          border-radius 4px
          background-color #a3a3ac
          box-shadow 0 0 1px rgba(255, 255, 255, .5)

        &::-webkit-scrollbar-track
          border-radius 4px
          background-color #f4eaeb
</style>
