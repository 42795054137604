export const statuses = {
  new: 'Новый',
  NEW: 'Новый',
  parsing_source: 'Идет парсинг источника',
  ready_for_import: 'Готов к импорту',
  importing: 'Импортируется',
  PROCESSING: 'Выгружается',
  done: 'Завершён',
  FINISHED: 'Завершён',
  error: 'Ошибка',
  ERROR: 'Ошибка',
  cancelled: 'Отменен'
}

export const notificationTypes = {
  new: 'info',
  NEW: 'warning',
  parsing_source: 'warning',
  ready_for_import: 'warning',
  cancelled: 'warning',
  error: 'error',
  importing: 'success',
  PROCESSING: 'info',
  done: 'success',
  FINISHED: 'success'
}

export const notificationIcons = {
  new: 'bell',
  NEW: 'pause',
  parsing_source: 'loader--mini',
  ready_for_import: 'exclamation',
  importing: 'loader--mini',
  PROCESSING: 'info',
  cancelled: 'info',
  error: 'error-triangle',
  done: 'success-fill',
  FINISHED: 'success-fill'
}

export const notificationTexts = {
  import: {
    new: 'Новый',
    parsing_source: 'Идет парсинг источника',
    ready_for_import: 'Список на выгрузку в ожидании запуска',
    importing: 'Товар  импортируется',
    done: 'Список успешно загружен',
    error: 'При импорте возникли ошибки.',
    cancelled: 'Импорт отменен'
  },
  export: {
    NEW: 'Список на выгрузку в ожидании запуска',
    PROCESSING: 'Выгружается',
    paused: 'Выгрузка временно приостановлена',
    ERROR: 'При выгрузке возникли ошибки.',
    FINISHED: 'Список успешно загружен!'
  }
}

export const startableStatuses = {
  import: [
    'ready_for_import',
    'cancelled'
  ],
  export: ['NEW']
}

export const restartableStatuses = [
  'error'
]

export const deletableStatuses = {
  import: [
    'new',
    'done',
    'ready_for_import',
    'cancelled',
    'error'
  ],
  export: [
    'NEW',
    'FINISHED',
    'ERROR'
  ]
}
