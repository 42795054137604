<template>
  <div
    class="pagination"
  >
    <select-field
      border
      secondary
      :value="limit"
      :options="limitOptions"
      :searchable="false"
      :allow-empty="false"
      @select="value => limit = value"
    />

    <ul class="pagination__items">
      <li class="pagination__item pagination__item_content_first">
        <button
          class="pagination__button"
          type="button"
          :disabled="currentPage === 1"
          @click="clickHandler(1)"
        >
          <svg-icon
            class="pagination__button-icon"
            name="arrow-double-left"
          />
        </button>
      </li>

      <li class="pagination__item pagination__item_content_prev">
        <button
          class="pagination__button"
          type="button"
          :disabled="currentPage === 1"
          @click="showPrev"
        >
          <svg-icon
            name="chevron-left"
            class="pagination__button-icon"
          />
        </button>
      </li>

      <li
        v-if="hasFirst()"
        class="pagination__item"
      >
        <button
          class="pagination__button"
          type="button"
          :class="{ 'active': 1 === current }"
          @click="clickHandler(1)"
        >
          1
        </button>
      </li>

      <li
        v-if="hasFirst() && rangeStart !== 2"
        class="pagination__item pagination__item_delimiter"
      >
        <button
          class="pagination__button"
          type="button"
          @click="showPrev()"
        >
          &hellip;
        </button>
      </li>

      <li
        v-for="page in pages"
        :key="page"
        class="pagination__item"
      >
        <button
          class="pagination__button"
          type="button"
          :class="{ 'active': page === current }"
          @click="clickHandler(page)"
        >
          {{ page }}
        </button>
      </li>

      <li
        v-if="hasLast() && rangeEnd !== totalPages - 1"
        class="pagination__item pagination__item_delimeter"
      >
        <button
          class="pagination__button"
          type="button"
          @click="showNext()"
        >
          &hellip;
        </button>
      </li>

      <li
        class="pagination__item pagination__item_content_next"
      >
        <button
          class="pagination__button"
          type="button"
          :disabled="currentPage === totalPages"
          @click="showNext"
        >
          <svg-icon
            name="chevron-right"
            class="pagination__button-icon"
          />
        </button>
      </li>

      <li class="pagination__item pagination__item_content_last">
        <button
          class="pagination__button"
          type="button"
          :disabled="currentPage === totalPages"
          @click="clickHandler(totalPages)"
        >
          <svg-icon
            class="pagination__button-icon"
            name="arrow-double-right"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { DEFAULT_PAGINATION_LIMIT, limitList } from '@/constants/pagination'
import SelectField from '@/components/Interface/Select.vue'

export default {
  components: { SelectField },

  props: {
    data: {
      type: Object,
      required: true
    },

    currentPage: {
      type: Number,
      default: 1
    },

    pageRange: {
      type: Number,
      default: 1
    },

    pageCount: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      current: this.currentPage,
      limitOptions: limitList
    }
  },

  watch: {
    currentPage (newValue, oldValue) {
      if (newValue === oldValue) return

      this.current = newValue
      this.setRoutePage()
    },

    current (page) {
      this.$emit('change', { page, offset: this.limit * (page - 1), limit: this.limit })
    }
  },

  computed: {
    pages () {
      const pages = []

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages
    },

    limit: {
      get () {
        return this.data.limit ?? DEFAULT_PAGINATION_LIMIT
      },

      set (limit) {
        this.$emit('change', { page: 1, offset: 0, limit })
        this.$router.replace({
          query: { ...this.$route.query, page: 1, limit }
        })
      }
    },

    totalPages () {
      const totalPages = Math.round(this.data.count / this.limit)

      return this.data.count % this.limit === 0 || totalPages * this.limit >= this.data.count ? totalPages : totalPages + 1
    },

    rangeStart () {
      const start = this.current - this.pageRange
      return start > 0 ? start : 1
    },

    rangeEnd () {
      const end = this.current + this.pageCount - 1

      return end <= this.totalPages ? end : this.totalPages
    }
  },

  methods: {
    hasFirst () {
      return this.rangeStart !== 1
    },

    hasLast () {
      return this.rangeEnd < this.totalPages
    },

    setRoutePage () {
      this.$router.replace({
        query: { ...this.$route.query, page: this.current }
      })
    },

    showPrev () {
      this.current = this.current - this.pageRange > 0 ? this.current - this.pageRange : 1
      this.setRoutePage()
    },

    showNext () {
      this.current = this.current + this.pageRange < this.totalPages ? this.current + this.pageRange : this.totalPages
      this.setRoutePage()
    },

    clickHandler (page) {
      this.current = page
      this.setRoutePage()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .pagination
    display flex

    &__items
      display flex
      align-items center
      justify-content flex-start
      margin 0
      padding 0
      list-style none

    &__item
      &_content_first
        margin-right 25px

      &_content_prev
        margin-right 14px

        .pagination__button-icon
          width 6px
          height 12px

      &_content_next
        margin-left 7px

        .pagination__button-icon
          width 6px
          height 12px

      &_content_last
        margin-left 25px

    &__button
      display block
      width 34px
      height 34px
      font-family 'proxima_nova_regular'
      font-size 12px
      line-height 34px
      text-align center
      color #7b7b85
      border-radius 4px
      outline none
      transition color .3s cubic-bezier(.64, .09, .08, 1), border-color .3s cubic-bezier(.64, .09, .08, 1), background-color .3s cubic-bezier(.64, .09, .08, 1)
      cursor pointer
      box-sizing border-box
      -webkit-tap-highlight-color transparent
      user-select none

      &:hover
        color #fff
        background-color #5b40ff

        .pagination__button-icon
          stroke #fff

      &:focus
        outline none

      &[disabled]
        opacity .5
        cursor not-allowed

        &:hover
          color inherit
          background-color inherit

          .pagination__button-icon
            stroke #7b7b85

      &.active
        color #fff
        background-color #5b40ff
        cursor default
        pointer-events none

    &__button-icon
      width 24px
      height 24px
      vertical-align middle
      fill none
      stroke #7b7b85
      transition stroke .3s cubic-bezier(.64, .09, .08, 1)
</style>
